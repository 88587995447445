/**
 * @fileoverview gRPC-Web generated client stub for transporter_truck
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/transporter_truck/transporter_truck.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_transporter_truck_transporter_truck_pb from '../../protobuf/transporter_truck/transporter_truck_pb';


export class TransporterTruckServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.CreateTransporterTruckRequest,
    protobuf_model_merged_pb.TransporterTruckResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.CreateTransporterTruckRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterTruckResponse.deserializeBinary
  );

  create(
    request: protobuf_transporter_truck_transporter_truck_pb.CreateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterTruckResponse>;

  create(
    request: protobuf_transporter_truck_transporter_truck_pb.CreateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterTruckResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterTruckResponse>;

  create(
    request: protobuf_transporter_truck_transporter_truck_pb.CreateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterTruckResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.UpdateTransporterTruckRequest,
    protobuf_model_merged_pb.TransporterTruckResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.UpdateTransporterTruckRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.TransporterTruckResponse.deserializeBinary
  );

  update(
    request: protobuf_transporter_truck_transporter_truck_pb.UpdateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.TransporterTruckResponse>;

  update(
    request: protobuf_transporter_truck_transporter_truck_pb.UpdateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterTruckResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.TransporterTruckResponse>;

  update(
    request: protobuf_transporter_truck_transporter_truck_pb.UpdateTransporterTruckRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.TransporterTruckResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksRequest,
    protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse.deserializeBinary
  );

  get(
    request: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse>;

  get(
    request: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse>;

  get(
    request: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.GetTransporterTrucksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksRequest,
    protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse.deserializeBinary
  );

  delete(
    request: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse>;

  delete(
    request: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse>;

  delete(
    request: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.DeleteTransporterTrucksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksRequest,
    protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse.deserializeBinary
  );

  list(
    request: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse>;

  list(
    request: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse>;

  list(
    request: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.ListTransporterTrucksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/transporter_truck.TransporterTruckService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksRequest,
    protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse,
    (request: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksRequest) => {
      return request.serializeBinary();
    },
    protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse.deserializeBinary
  );

  export(
    request: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse>;

  export(
    request: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse) => void): grpcWeb.ClientReadableStream<protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse>;

  export(
    request: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_transporter_truck_transporter_truck_pb.ExportTransporterTrucksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transporter_truck.TransporterTruckService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transporter_truck.TransporterTruckService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

