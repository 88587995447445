/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/user/service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_user_auth_pb from '../../protobuf/user/auth_pb';
import * as protobuf_user_user_pb from '../../protobuf/user/user_pb';


export class AuthServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAuthenticate = new grpcWeb.MethodDescriptor(
    '/user.AuthService/Authenticate',
    grpcWeb.MethodType.UNARY,
    protobuf_user_auth_pb.AuthRequest,
    protobuf_user_auth_pb.SessionDetailsResponse,
    (request: protobuf_user_auth_pb.AuthRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_auth_pb.SessionDetailsResponse.deserializeBinary
  );

  authenticate(
    request: protobuf_user_auth_pb.AuthRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_auth_pb.SessionDetailsResponse>;

  authenticate(
    request: protobuf_user_auth_pb.AuthRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_auth_pb.SessionDetailsResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_auth_pb.SessionDetailsResponse>;

  authenticate(
    request: protobuf_user_auth_pb.AuthRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_auth_pb.SessionDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.AuthService/Authenticate',
        request,
        metadata || {},
        this.methodDescriptorAuthenticate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.AuthService/Authenticate',
    request,
    metadata || {},
    this.methodDescriptorAuthenticate);
  }

}

export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListMeta = new grpcWeb.MethodDescriptor(
    '/user.UserService/ListMeta',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.ListMetaUsersRequest,
    protobuf_user_user_pb.ListMetaUsersResponse,
    (request: protobuf_user_user_pb.ListMetaUsersRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.ListMetaUsersResponse.deserializeBinary
  );

  listMeta(
    request: protobuf_user_user_pb.ListMetaUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.ListMetaUsersResponse>;

  listMeta(
    request: protobuf_user_user_pb.ListMetaUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ListMetaUsersResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.ListMetaUsersResponse>;

  listMeta(
    request: protobuf_user_user_pb.ListMetaUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ListMetaUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/ListMeta',
        request,
        metadata || {},
        this.methodDescriptorListMeta,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/ListMeta',
    request,
    metadata || {},
    this.methodDescriptorListMeta);
  }

  methodDescriptorMe = new grpcWeb.MethodDescriptor(
    '/user.UserService/Me',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.MeRequest,
    protobuf_model_merged_pb.UserResponse,
    (request: protobuf_user_user_pb.MeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.UserResponse.deserializeBinary
  );

  me(
    request: protobuf_user_user_pb.MeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.UserResponse>;

  me(
    request: protobuf_user_user_pb.MeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.UserResponse>;

  me(
    request: protobuf_user_user_pb.MeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Me',
        request,
        metadata || {},
        this.methodDescriptorMe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Me',
    request,
    metadata || {},
    this.methodDescriptorMe);
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/user.UserService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.CreateUserRequest,
    protobuf_model_merged_pb.UserResponse,
    (request: protobuf_user_user_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.UserResponse.deserializeBinary
  );

  create(
    request: protobuf_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.UserResponse>;

  create(
    request: protobuf_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.UserResponse>;

  create(
    request: protobuf_user_user_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/user.UserService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.UpdateUserRequest,
    protobuf_model_merged_pb.UserResponse,
    (request: protobuf_user_user_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.UserResponse.deserializeBinary
  );

  update(
    request: protobuf_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.UserResponse>;

  update(
    request: protobuf_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.UserResponse>;

  update(
    request: protobuf_user_user_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.UserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/user.UserService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.GetUsersRequest,
    protobuf_user_user_pb.GetUsersResponse,
    (request: protobuf_user_user_pb.GetUsersRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.GetUsersResponse.deserializeBinary
  );

  get(
    request: protobuf_user_user_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.GetUsersResponse>;

  get(
    request: protobuf_user_user_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.GetUsersResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.GetUsersResponse>;

  get(
    request: protobuf_user_user_pb.GetUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.GetUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/user.UserService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.DeleteUsersRequest,
    protobuf_user_user_pb.DeleteUsersResponse,
    (request: protobuf_user_user_pb.DeleteUsersRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.DeleteUsersResponse.deserializeBinary
  );

  delete(
    request: protobuf_user_user_pb.DeleteUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.DeleteUsersResponse>;

  delete(
    request: protobuf_user_user_pb.DeleteUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.DeleteUsersResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.DeleteUsersResponse>;

  delete(
    request: protobuf_user_user_pb.DeleteUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.DeleteUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/user.UserService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.ListUsersRequest,
    protobuf_user_user_pb.ListUsersResponse,
    (request: protobuf_user_user_pb.ListUsersRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.ListUsersResponse.deserializeBinary
  );

  list(
    request: protobuf_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.ListUsersResponse>;

  list(
    request: protobuf_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ListUsersResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.ListUsersResponse>;

  list(
    request: protobuf_user_user_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ListUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/user.UserService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.ExportUsersRequest,
    protobuf_user_user_pb.ExportUsersResponse,
    (request: protobuf_user_user_pb.ExportUsersRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.ExportUsersResponse.deserializeBinary
  );

  export(
    request: protobuf_user_user_pb.ExportUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.ExportUsersResponse>;

  export(
    request: protobuf_user_user_pb.ExportUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ExportUsersResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.ExportUsersResponse>;

  export(
    request: protobuf_user_user_pb.ExportUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.ExportUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

  methodDescriptorSetMyLanguage = new grpcWeb.MethodDescriptor(
    '/user.UserService/SetMyLanguage',
    grpcWeb.MethodType.UNARY,
    protobuf_user_user_pb.SetMyLanguageRequest,
    protobuf_user_user_pb.SetMyLanguageResponse,
    (request: protobuf_user_user_pb.SetMyLanguageRequest) => {
      return request.serializeBinary();
    },
    protobuf_user_user_pb.SetMyLanguageResponse.deserializeBinary
  );

  setMyLanguage(
    request: protobuf_user_user_pb.SetMyLanguageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_user_user_pb.SetMyLanguageResponse>;

  setMyLanguage(
    request: protobuf_user_user_pb.SetMyLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.SetMyLanguageResponse) => void): grpcWeb.ClientReadableStream<protobuf_user_user_pb.SetMyLanguageResponse>;

  setMyLanguage(
    request: protobuf_user_user_pb.SetMyLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_user_user_pb.SetMyLanguageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/user.UserService/SetMyLanguage',
        request,
        metadata || {},
        this.methodDescriptorSetMyLanguage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/user.UserService/SetMyLanguage',
    request,
    metadata || {},
    this.methodDescriptorSetMyLanguage);
  }

}

